import React from "react";
import { navItems } from "../../CampaignHeader/CampaignHeader";
import CampaignSection from "../../components/CampaignSection";
import CampaignLogoTitle from "../../CampaignLogoTitle";

import Bohatery from "./bohatery.png";
import "./CampaignAboutSection.scss";

const CampaignAboutSection = () => {
  return (
    <div id={navItems.about.link} className="campaign-about-section">
      <img
        className="campaign-about-section__image"
        src={Bohatery}
        alt="bohatery"
      />
      <CampaignSection
        className="campaign-about-section-description"
        subtitle={navItems.about.label}
        title={
          <>
            Dlaczego organizujemy kampanię Nie przekonuj się{" "}
            <CampaignLogoTitle />?
          </>
        }
        paragraph={
          "W ciągu ostatnich 30 lat w Polsce zachorowalność na czerniaka złośliwego wzrosła aż o 300%. Według prognoz z najnowszych badań, do 2040 roku liczby przypadków na całym świecie zwiększy się o 57%, a śmiertelność z powodu tej choroby aż o 68%."
        }
      />
    </div>
  );
};

export default CampaignAboutSection;
