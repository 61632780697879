import React from "react";
import "./CampaignAboutText.scss";

export const Paragraph = ({ children }) => {
  return <p className="campaign-about-text__paragraph">{children}</p>;
};

export const Bold = ({ children }) => {
  return <b className="campaign-about-text__paragraph--bold">{children}</b>;
};

const CampaignAboutText = ({
  className,
  title,
  children,
}: {
  className?: string;
  title?: string;
  children: React.ReactElement | React.ReactElement[];
}) => {
  return (
    <section className={`${className ? className : ""} campaign-about-text`}>
      {title && <h4 className="campaign-about-text__title">{title}</h4>}
      {children}
    </section>
  );
};

export default CampaignAboutText;
