import React from "react";
import CampaignJoin from "../CampaignMain/CampaignJoin";
import CampaignAboutText, { Paragraph, Bold } from "./CampaignAboutText";
import CampaignAboutSection from "./CampaignAboutSection";

import ExpertPreview1 from "./expertPreview1.png";
import ExpertPreview2 from "./expertPreview2.png";

import "./CampaignAboutMain.scss";
import SectionWrapper from "../components/SectionWrapper";
import HeroVideo from "../CampaignMain/CampaignHero/HeroVideo";
import SEO from "../../SEO";

const CampaignAboutMain = () => {
  return (
    <div className="campaign-about-main">
      <SEO
        title="Nie przekonuj się #nawłasnejskórze - Kampania edukacyjna o konieczności stosowania profilaktyki nowotworów skóry"
        description="Chcemy pokazać, że wszyscy mniej lub bardziej, możemy być narażeni na zachorowanie na nowotwory skóry, bo czynników ryzyka jest bardzo dużo. "
        keywords="czerniak, badanie znamion, nawlasnejskorze, nawłasnejskórze, #nawłasnejskórze, na własnej skórze, twoje znamiona, twojeznamiona.pl, kampania społeczna, kampania edukacyjna"
      />
      <CampaignAboutSection />
      <SectionWrapper className="campaign-about-main__info">
        <CampaignAboutText title="Niestety, mimo tych zatrważających danych, poziom świadomości na temat czerniaka wciąż jest niewystarczający.">
          <Paragraph>
            Lekarze podkreślają, że pacjenci często bagatelizują problem,
            uważają, że ochronę przed słońcem należy stosować tylko podczas
            wakacyjnego urlopu nad morzem, ciemna karnacja jest gwarancją
            zdrowego opalania, a same znamiona postrzegają głównie w kategoriach
            estetycznych.
          </Paragraph>
          <Paragraph>
            Tych i innych mitów słyszeliśmy bardzo wiele. Właśnie dlatego
            pragniemy dotrzeć do jak największej liczby osób i przekonać je, że
            nowotwory skóry to poważne zagrożenie, które dotyczy każdego.
            Niemniej jednak, celem naszej kampanii nie jest (tylko) wzbudzenie
            strachu. Przede wszystkim zależy nam, aby uświadamiać, że wystarczy
            podjąć proste kroki, by znacząco zmniejszyć ryzyko zachorowania na
            czerniaka, lub wykryć go we wczesnym stadium.
          </Paragraph>
        </CampaignAboutText>
        <p className="campaign-about-main__video_title" id="czyzewska">O kampanii opowiada specjalistka onkologii klinicznej Agnieszka Czyżewska:</p>

        <div className="campaign-about-main__video-wrapper">
          <HeroVideo
            url="https://youtu.be/lR9PgkHZqU4"
            heroPreview={ExpertPreview1}
            isModal
          />
        </div>

        <CampaignAboutText title="Dzięki postępom w badaniach naukowych, mamy coraz większe możliwości skutecznego leczenia czerniaka.">
          <Paragraph>
            Jednak, aby zwiększyć szanse na powrót do zdrowia, kluczowe jest
            wykrywanie go na wczesnym etapie. Proste, nieinwazyjne badanie
            dermatoskopowe pozwala diagnozować nawet bardzo małe zmiany na
            skórze, które wymagają usunięcia.
          </Paragraph>
        </CampaignAboutText>
        
        <p className="campaign-about-main__video_title" id="galus">O czerniaku opowiada specjalista onkologii klinicznej dr n. med. Łukasz Galus:</p>

        <div className="campaign-about-main__video-wrapper">
          <HeroVideo
            url="https://youtu.be/-mSvE4oi4Jc"
            heroPreview={ExpertPreview2}
            isModal
          />
        </div>
        <CampaignAboutText title="W ramach kampanii  NIE PRZEKONUJ SIĘ #NAWŁASNEJSKÓRZE przygotowaliśmy materiały wideo, ukazujące najczęstsze wymówki i tłumaczenia, jakie spotykamy w kontekście czerniaka.">
          <Paragraph>
            Chcemy pokazać, że wszyscy mniej lub bardziej, możemy być narażeni
            na zachorowanie na nowotwory skóry, bo czynników ryzyka jest bardzo
            dużo. Ponadto opracowaliśmy szereg materiałów graficznych, w tym
            plakatów i infografik, których celem jest przybliżenie odbiorcom z
            różnych grup społecznych zagadnień związanych z czerniakiem oraz
            profilaktyką nowotworów skóry.
          </Paragraph>
          <Paragraph>
            Nieocenione wsparcie naszej kampanii zaoferowały instytucje i
            organizacje m.in. Polska Koalicja Pacjentów Onkologicznych, Fundacja
            Rakiety, Fundacja Życie z Rakiem, Stowarzyszenie Dzieciaki Chojraki,
            Łódzka Fundacja Kocham Życie, Drużyna Szpiku, Warszawskie
            Towarzystwo Psychoonkologii.
          </Paragraph>
        </CampaignAboutText>
        <CampaignAboutText title="Wierzymy w moc Internetu, dlatego do współpracy w nagłośnieniu kampanii zaprosiliśmy influencerów, których zasięgi i siła przekonywania są nieocenione. Liczymy, że zaangażowanie twórców internetowych pozwoli nam dotrzeć do jeszcze większego grona osób.">
          <Paragraph>
            Serdecznie zachęcamy wszystkich do wsparcia naszej kampanii i
            dołączenia do akcji. Edukacja jest kluczem do ochrony przed
            czerniakiem, a razem możemy zadbać o więcej osób!
          </Paragraph>
          <Paragraph>
            Kampania społeczna <Bold>#nawłasnejskórze</Bold> realizowana jest w
            ramach projektu “Twoje Znamiona” nadzorowanego przez Narodowe
            Centrum Badań i Rozwoju, dofinansowanego z Funduszy Europejskich.
          </Paragraph>
          <Paragraph>
            Jednym z zadań realizowanych w ramach ww. projektu jest dotarcie do
            pacjentów i zwiększenie świadomości o konieczności stosowania
            profilaktyki w kierunku nowotworów skóry i regularnych badań
            dermatoskopowych.
          </Paragraph>
        </CampaignAboutText>
        <CampaignAboutText className="campaign-about-main__medify-text-section">
          <p className="campaign-about-main__medify-text-paragraph">
            Realizatorem projektu jest Medify.Me sp. z o.o., akcję wspierają
            m.in. organizacje pożytku publicznego, ambasadorzy oraz
            influencerzy.
          </p>
        </CampaignAboutText>
        <CampaignAboutText
          className="campaign-about-main__bibliography"
          title="Bibliografia"
        >
          <Paragraph>
            Materiały i statystyki wykorzystywane w kampanii pochodzą z polskich
            i międzynarodowych tekstów źródłowych:
            <ul>
              <li>
                <p>
                  Shakeel M, Jiyad Z, Grant M, Cook MG, Oudit D, Green AC.
                  Melanoma predilection for the lower limbs of women compared
                  with men. Arch Dermatol Res. 2023 Apr;315(3):633-636. doi:
                  10.1007/s00403-022-02375-7. Epub 2022 Sep 14. PMID: 36104631.
                  <a href="https://pubmed.ncbi.nlm.nih.gov/36104631/">
                    https://pubmed.ncbi.nlm.nih.gov/36104631/
                  </a>
                </p>
              </li>

              <li>
                <p>
                  Cymerman RM, Shao Y, Wang K, Zhang Y, Murzaku EC, Penn LA,
                  Osman I, Polsky D. De Novo vs Nevus-Associated Melanomas:
                  Differences in Associations With Prognostic Indicators and
                  Survival. J Natl Cancer Inst. 2016 May 27;108(10):djw121. doi:
                  10.1093/jnci/djw121. PMID: 27235387; PMCID: PMC5939856.
                </p>
              </li>

              <li>
                <p>
                  Carli P, de Giorgi V, Chiarugi A, Nardini P, Weinstock MA,
                  Crocetti E, Stante M, Giannotti B. Addition of dermoscopy to
                  conventional naked-eye examination in melanoma screening: a
                  randomized study. J Am Acad Dermatol. 2004 May;50(5):683-9.
                  doi: 10.1016/j.jaad.2003.09.009. PMID: 15097950.
                </p>
              </li>
              <li>
                <p>
                  Dziankowska-Zaborszczyk E, Maniecka-Bryła I, Pikala M.
                  Mortality Trends Due to Skin Melanoma in Poland in the Years
                  2000-2020. Int J Environ Res Public Health. 2022 Dec
                  2;19(23):16118. doi: 10.3390/ijerph192316118. PMID: 36498192;
                  PMCID: PMC9739595.
                </p>
              </li>
              <li>
                <p>
                  Avilés-Izquierdo JA, Molina-López I, Rodríguez-Lomba E,
                  Marquez-Rodas I, Suarez-Fernandez R, Lazaro-Ochaita P. Who
                  detects melanoma? Impact of detection patterns on
                  characteristics and prognosis of patients with melanoma. J Am
                  Acad Dermatol. 2016 Nov;75(5):967-974. doi:
                  10.1016/j.jaad.2016.07.009. Epub 2016 Sep 16. PMID: 27645105.
                </p>
              </li>

              <li>
                <p>
                  Zipser MC, Mangana J, Oberholzer PA, French LE, Dummer R.
                  Melanoma after laser therapy of pigmented
                  lesions--circumstances and outcome. Eur J Dermatol. 2010
                  May-Jun;20(3):334-8. doi: 10.168 4/ejd.2010.0933. Epub 2010
                  Apr 27. PMID: 20423817.
                </p>
              </li>

              <li>
                <p>
                  The National institute on aging has characterized the aging of
                  our society as a “silver tsunami for which we are unprepared”.
                </p>
              </li>
              <li>
                <p>
                  Fried LP, Hall WJ (2008). Editorial: Leading on behalf of an
                  aging society. J Am Geriatr Soc, 56:1791-5.
                </p>
              </li>
              <li>
                <p>
                  Balducci L (2005). Epidemiology of cancer and aging. J Oncol
                  Manag, 14:47-50
                </p>
              </li>

              <li>
                <p>
                  Sena JS, Girão RJ, Carvalho SM, Tavares RM, Fonseca FL, Silva
                  PB, Barbosa MC. Occupational skin cancer: Systematic review.
                  Rev Assoc Med Bras (1992). 2016 May-Jun;62(3):280-6. doi:
                  10.1590/1806-9282.62.03.280. PMID: 27310554.
                </p>
              </li>

              <li>
                <p>
                  Arnold M, Singh D, Laversanne M, et al. Global Burden of
                  Cutaneous Melanoma in 2020 and Projections to 2040. JAMA
                  Dermatol. 2022;158(5):495–503.
                  doi:10.1001/jamadermatol.2022.0160
                </p>
              </li>
              <li>
                <p>
                  <a href="https://pubmed.ncbi.nlm.nih.gov/35353115/">
                    https://pubmed.ncbi.nlm.nih.gov/35353115/
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="http://onkologia.zalecenia.med.pl/pdf/zalecenia_PTOK_tom1_21_Raki_skory_20211110.pdf">
                    http://onkologia.zalecenia.med.pl/pdf/zalecenia_PTOK_tom1_21_Raki_skory_20211110.pdf
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="http://onkologia.zalecenia.med.pl/pdf/zalecenia_PTOK_tom1_09_Czerniaki_skory_20211230.pdf">
                    http://onkologia.zalecenia.med.pl/pdf/zalecenia_PTOK_tom1_09_Czerniaki_skory_20211230.pdf
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://onkologia.org.pl/sites/default/files/publications/2023-01/nowotwory_2020.pdf">
                    https://onkologia.org.pl/sites/default/files/publications/2023-01/nowotwory_2020.pdf
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://pubmed.ncbi.nlm.nih.gov/31230976/">
                    https://pubmed.ncbi.nlm.nih.gov/31230976/
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.ncbi.nlm.nih.gov/books/NBK321117">
                    https://www.ncbi.nlm.nih.gov/books/NBK321117
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.termedia.pl/Fenotyp-pacjentow-z-czerniakiem-skory-znamionami-dysplastycznymi-oraz-znamionami-zwyklymi,56,16039,1,0.html">
                    https://www.termedia.pl/Fenotyp-pacjentow-z-czerniakiem-skory-znamionami-dysplastycznymi-oraz-znamionami-zwyklymi,56,16039,1,0.html
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://pubmed.ncbi.nlm.nih.gov/16018199/">
                    https://pubmed.ncbi.nlm.nih.gov/16018199/
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://pubmed.ncbi.nlm.nih.gov/27310554/">
                    https://pubmed.ncbi.nlm.nih.gov/27310554/
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.aad.org/media/stats-skin-cancer">
                    https://www.aad.org/media/stats-skin-cancer
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://pubmed.ncbi.nlm.nih.gov/20423817/">
                    https://pubmed.ncbi.nlm.nih.gov/20423817/
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.aad.org/media/stats-melanoma">
                    https://www.aad.org/media/stats-melanoma
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://pubmed.ncbi.nlm.nih.gov/27645105/">
                    https://pubmed.ncbi.nlm.nih.gov/27645105/
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://pubmed.ncbi.nlm.nih.gov/18280899/ ">
                    https://pubmed.ncbi.nlm.nih.gov/18280899/
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.washingtonpost.com/nation/2023/05/09/skin-cancer-smallest-mole-oregon/">
                    https://www.washingtonpost.com/nation/2023/05/09/skin-cancer-smallest-mole-oregon/
                  </a>
                </p>
              </li>
            </ul>
          </Paragraph>
        </CampaignAboutText>
      </SectionWrapper>
      <CampaignJoin />
    </div>
  );
};

export default CampaignAboutMain;
