import React from "react";
import "./CampaignPage.scss";

import CampaignLayout from "../../components/campaign/CampaignLayout/CampaignLayout";
import CampaignAboutMain from "../../components/campaign/CampaignAboutMain";

const CampaignAboutPage = ({ location }) => {
  return (
    <CampaignLayout location={location}>
      <CampaignAboutMain />
    </CampaignLayout>
  );
};

export default CampaignAboutPage;
